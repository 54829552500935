
<div *ngIf="companyConf  == null || datosLegalesEmpresa == null || ((families== null || superGroups == null));
    else elseContent1" class="align-items-center d-flex justify-content-center container-load">
  <div class="w-100">
    <div class="w-100 d-flex justify-content-center mb-3">
      <img src="assets/loading/loading.gif"  alt="Cargando icono" title="Icono cargando datos"
           style="height: 150px !important; width: auto !important;" />
    </div>
    <div class="text-center">
      <h3>Estamos cargando todos los datos</h3>
      <p style="font-size: 14px">Espere un momento</p>
    </div>
  </div>
</div>

<ng-template #elseContent1>
  <div [hidden]="showMenu">

    <!-- NAVBAR PARTICULARES -->
    <div *ngIf="companyConf.tipoMenu != 2 && companyConf.tipoMenu != 3">
      <div class="navbar-computer">
        <nav class="pe-2 ps-2 pt-2 bg-secondary text-secondary d-flex">
          <div class="col-md-4">
            <div class="mt-2 ps-0">
              <ul class="list-unstyled d-flex px-3" *ngIf="companyConf.paginaEstatica">
                <li class="nav-item pe-2">
                  <a class="nav-link text-menu-secondary mx-3" routerLink="/" style="font-size: 1em !important;">
                    Inicio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-menu-secondary mx-3" routerLink="/acerca-de"
                     style="font-size: 1em !important;">Acerca de</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link text-menu-secondary mx-3" routerLink="/atencion-al-cliente"
                     style="font-size: 1em !important;">Atención al cliente</a>
                </li>
              </ul>

              <ul class="list-unstyled d-flex" *ngIf="!companyConf.paginaEstatica">
                <li>
                  <a *ngIf="companyConf.inicio != undefined && companyConf.inicio != ''"
                     class="nav-link text-menu-secondary pb-0 me-2" routerLink="/"
                     style="font-size: 0.875em !important;">Inicio</a>
                </li>
                <li>
                  <a *ngIf="companyConf.acercaDe != undefined && companyConf.acercaDe != ''"
                     class="nav-link text-menu-secondary pb-0 me-2" [href]="companyConf.acercaDe"
                     style="font-size: 0.875em !important;">Acerca de</a>
                </li>
                <li>
                  <a *ngIf="companyConf.atencionCliente != undefined && companyConf.atencionCliente != ''"
                     class="nav-link text-menu-secondary pb-0" [href]="companyConf.atencionCliente"
                     style="font-size: 0.875em !important;">Atención al cliente</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div [@animSlider]="counter" class="w-100 text-center mt-1">
              <ng-container *ngFor="let texto of textos; let i=index">
                <div *ngIf="i==counter" name="{{i}}" class="text-menu-secondary" style="font-size: 0.875em !important;">
                  {{texto}}
                </div>
              </ng-container>
            </div>
          </div>

          <div class="col-md-4 mt-2">
            <div class="w-100 d-flex justify-content-end">

              <!--<a class="nav-link">
                <div id="google_translate_element"></div>
              </a>-->
              <a class="nav-link text-menu-secondary pb-0 me-5" routerLink="/iniciarSesion"
                 style="font-size: 0.875em !important;" *ngIf="!isUser; else elseIsUser">
                <div class="d-flex align-items-center" style="font-size: 1em !important;">
                  <i class="fa-solid fa-circle-user fa-2x me-2"></i>Entrar
                </div>
              </a>
              <ng-template #elseIsUser>
                <a class="nav-link text-menu-secondary pb-0" style="font-size: 0.875em !important;"
                   title="Cerrar sesión">
                  <a class="d-flex align-items-center text-decoration-none text-black" (click)="logOut()" [routerLink]="'/iniciarSesion'">
                    <i class="fa-solid fa-sign-out-alt fa-2x me-2"></i>Cerrar sesión
                  </a>
                </a>
              </ng-template>
            </div>
          </div>
        </nav>
        <nav class="bg-primary text-light  ps-4 pe-4 pt-4 pb-3 d-flex">
          <div class="col-md-4">
            <img id="logo" src="assets/logo-menu.png" alt="Logo de la empresa" title="Logo de la empresa" height="50px">
          </div>
          <div class="col-md-4"></div>

          <div class="col-md-4">
            <div class="w-100 d-flex justify-content-end">
              <a *ngIf="companyConf.paginaEstatica && companyConf.mostrarUbicaciones" class="nav-link text-dark pb-0"
                 routerLink="/atencion-al-cliente" fragment="ubicaciones" style="font-size: 15px !important;"
                 title="Ubicaciones">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fa-map-location-dot fa-2x me-1 text-light"></i>
                </div>
              </a>

              <a class="nav-link text-light pb-0 mx-3" style="font-size: 15px !important;" routerLink="/miPerfil"
                 *ngIf="isUser" title="Mi perfil">
                <div class="d-flex align-items-center">
                  <i class="fa-solid fa-user fa-2x me-1"></i>
                </div>
              </a>

              <a class="nav-link me-3 pointer mx-3" *ngIf="companyConf.storePickup && !companyConf.soloRecogidaTienda">
                <i class="fas fa-store me-2 text-light fa-1-5x" title="Recogida en tienda" style="color: #607d8b;"></i>
                <mat-slide-toggle name="nombre" [(ngModel)]="homeDelivery" (change)="setInfoRoute()"></mat-slide-toggle>
                <i class="fas fa-truck ms-2 text-light fa-2x" title="Envío a domicilio"></i> <br />
              </a>

              <a class="nav-link text-light pb-0 mx-3" style="font-size: 15px !important;" [routerLink]="'/carrito'"
                 title="Carrito">
                <div class="d-flex align-items-center">
                  <i class="fas fa-shopping-cart fa-2x"></i><span class='badge badge-warning lblCartCount'>
                    {{numberArticles}} </span>
                </div>
              </a>
            </div>
          </div>
        </nav>
      </div>

      <div class="groups">
        <app-groups [superGroups]="superGroups" [initRoute]="companyConf.initRoute"
                    [useQuickOrder]="companyConf.useQuickOrder" [repeatOrder]="companyConf.showRepeatOrderWindow"
                    [families]="families" [companyException]="companyConf.companyException"
                    [showAllMenu]="companyConf.showAllMenu" [portalDeClientes]="companyConf.portalDeClientes"></app-groups>
      </div>

      <div class="navbar-mobil">
        <nav class="navbar navbar-expand-lg navbar-light bg-white">
          <div class="container-fluid">
            <a class="navbar-brand" routerLink="/">
              <img src="assets/logo.png" alt="Logo empresa" style="width: auto; height: 3.75em;" />
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    (click)="toggleNavbar()"
                    data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNavAltMarkup" [class.show]="!isNavbarCollapsed">
              <ul class="navbar-nav me-auto w-100">
                <li class="nav-item active border-bottom" routerLink="/" (click)="toggleNavbar()">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      INICIO
                    </div>
                  </div>
                </li>

                <li class="nav-item active border-bottom" routerLink="/acerca-de" (click)="toggleNavbar()" *ngIf="companyConf.paginaEstatica">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      ACERCA DE
                    </div>
                  </div>
                </li>

                <li class="nav-item active border-bottom" routerLink="/atencion-al-cliente" (click)="toggleNavbar()" *ngIf="companyConf.paginaEstatica">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      CONTACTO
                    </div>
                  </div>
                </li>

                <li class="nav-item active border-bottom" routerLink="/compra/ofertas" *ngIf="showOffers" (click)="toggleNavbar()">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      <i class="fas fa-percent me-3"></i>NUESTRAS PROMOCIONES
                    </div>
                  </div>
                </li>
                <li *ngIf="isUser" class="nav-item active border-bottom" routerLink="/miPerfil/misPedidos" (click)="toggleNavbar()">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      <i class="fas fa-list-ol me-3"></i>MIS PEDIDOS
                    </div>
                  </div>
                </li>

                <li class="nav-item active border-bottom" routerLink="/compra/buscar" (click)="toggleNavbar()">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      BUSCAR ARTÍCULOS
                    </div>
                  </div>
                </li>

                <ng-container *ngFor="let superGroup of superGroups">
                  <div *ngFor="let group of superGroup.groups">
                    <li class="nav-item active border-bottom" *ngIf="group.id != ''" (click)="toggleNavbar()">
                      <a class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show"
                         [queryParams]="{ superGrupo: 'none', grupo: group.id }"
                         [routerLink]="redirectToGrupo(group.name)">
                        <div class="nav-style">
                          {{group.name}}
                        </div>
                      </a>
                    </li>
                  </div>
                </ng-container>

                <li class="nav-item active border-bottom" *ngIf="isUser" [routerLink]="'/iniciarSesion'"
                    (click)="toggleNavbar(); topPage(); logOut();">
                  <div class="nav-link pt-2 pb-2" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <div class="nav-style">
                      <i class="fas fa-sign-out-alt me-3"></i>CERRAR SESIÓN
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div *ngIf="companyConf.tipoMenu == 2">
      <nav class="navbar-computer ">
        <div class="bg-primary">
          <div class="row pt-3 pb-1 ms-1 me-1">
            <div class="col-10 bg-primary">
              <ul class="list-unstyled d-flex" *ngIf="companyConf.paginaEstatica">
                <li class="me-5">
                  <a class="nav-link a-primary pb-0" routerLink="/" (click)="topPage()"
                     style="font-size: 15px !important;">Inicio</a>
                </li>


                <li class="pe-2 border-right border-light me-2">
                  <a class="nav-link a-primary pb-0" routerLink="/atencion-al-cliente" fragment="ubicaciones"
                     style="font-size: 15px !important;">
                    <i class="fa-solid fa-map-location-dot me-1 text-light"></i> <span class="computer-lg">Puntos de
                      recogida</span>
                  </a>
                </li>

                <a class="text-decoration-none text-black" [routerLink]="'/carrito'" title="Cesta de la compra">
                  <div class="nav-link a-primary pb-0" style="font-size: 15px !important;">
                    <i class="fa-solid fa-basket-shopping me-1"></i><span class='badge badge-warning lblCartCount'>
                      {{numberArticles}}</span>
                    <span class="computer-lg">Cesta</span>
                  </div>
                </a>
              </ul>
            </div>

            <div class="col-2 ">
              <div class="float-right">
                <a class="nav-link a-primary pb-0" routerLink="/iniciarSesion" (click)="topPage()"
                   style="font-size: 15px !important;" *ngIf="!isUser; else elseIsUser">
                  <i class="fa-solid fa-right-to-bracket me-1 text-light"></i>
                  <span class="computer-lg">Iniciar sesión</span>
                </a>

                <ng-template #elseIsUser>
                  <a class="nav-link a-primary pb-0" routerLink="/miPerfil" (click)="topPage()"
                     style="font-size: 15px !important;">
                    <i class="fa-solid fa-user me-1"></i>
                    <span class="computer-lg">Mi perfil</span>
                  </a>
                </ng-template>

              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav class="navbar-computer mt-4">
        <div class="container">
          <div class="row" *ngIf="menu2 != undefined">
            <div class="col-2 mt-3" (mouseover)="menu2[0].mostrarDesplegable = menu2[0].desplegable.length > 0"
                 (mouseleave)="menu2[0].mostrarDesplegable = false">
              <a class="w-100 text-center pointer text-decoration-none text-black" [routerLink]="menu2[0].ruta">
                {{menu2[0].texto}}
                <i class="ms-2 fa-solid fa-caret-down" *ngIf="menu2[0].desplegable.length > 0"></i>
              </a>

              <div *ngIf="menu2[0].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[0].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" [routerLink]="opcion.ruta">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-2 mt-3" (mouseover)="menu2[1].mostrarDesplegable = menu2[1].desplegable.length > 0"
                 (mouseleave)="menu2[1].mostrarDesplegable = false">
              <a class="w-100 text-center pointer text-decoration-none text-black" [routerLink]="menu2[1].ruta">
                {{menu2[1].texto}}
                <i class="ms-2 fa-solid fa-caret-down" *ngIf="menu2[1].desplegable.length > 0"></i>
              </a>

              <div *ngIf="menu2[1].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[1].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" [routerLink]="opcion.ruta">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-4 h-100">
              <img src="assets/logo-alargado.png" width="auto" height="100%" alt="Logo de la empresa" title="Logo de la empresa" />
            </div>

            <div class="col-2 mt-3" (mouseover)="menu2[2].mostrarDesplegable = menu2[2].desplegable.length > 0"
                 (mouseleave)="menu2[2].mostrarDesplegable = false">
              <h3>
                <a class="w-100 text-center pointer text-black text-decoration-none" [routerLink]="menu2[2].ruta">
                  {{menu2[2].texto}} <i class="ms-2 fa-solid fa-caret-down" *ngIf="menu2[2].desplegable.length > 0"></i>
                </a>
              </h3>

              <div *ngIf="menu2[2].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[2].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" [routerLink]="opcion.ruta">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-2 mt-3" (mouseover)="menu2[3].mostrarDesplegable = menu2[3].desplegable.length > 0"
                 (mouseleave)="menu2[3].mostrarDesplegable = false">
              <a class="w-100 text-center pointer text-decoration-none text-black"  [routerLink]="menu2[3].ruta">
                <h3>
                  {{menu2[3].texto}}
                  <i class="ms-2 fa-solid fa-caret-down" *ngIf="menu2[3].desplegable.length > 0"></i>
                </h3>
              </a>

              <div *ngIf="menu2[3].mostrarDesplegable" class="position-absolute border border-secondary w-100 bg-white"
                   style="border-radius: 10px; z-index: 10 !important">
                <div class="pt-3">
                  <div *ngFor="let opcion of menu2[3].desplegable; let i = index">
                    <a class="text-decoration-none text-center text-dark pointer" [routerLink]="opcion.ruta">
                      <h3 [ngClass]="{'pb-3 border-bottom border-secondary': i != menu2[2].desplegable.length -1}">
                        {{opcion.texto}}
                      </h3>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav class="navbar navbar-expand-lg navbar-light bg-white navbar-mobil">
        <a class="navbar-brand bg-white">
          <img *ngIf="companyConf" src="assets/logo-alargado.png" class="bg-white" alt="Logo empresa"
               style="width: auto; height: 3.75em;" />
        </a>

        <button class="me-3 navbar-toggler" type="button" (click)="showMenu = true">
          <i class="fa-solid fa-bars fa-2x text-primary"></i>
        </button>
      </nav>
    </div>

    <div *ngIf="companyConf.tipoMenu == 3" >
      <nav class="navbar navbar-expand-xl navbar-dark bg-secondary" *ngIf="posicionamiento != undefined">
        <div *ngIf="!posicionamiento.UrlInicio.startsWith('/'); else elseLogoWeb">
          <a class="navbar-brand ps-3" [href]="posicionamiento.UrlInicio">
            <img src="assets/logo-menu.png" alt="Logo de la empresa" title="Logo de la empresa" height="70px">
          </a>
        </div>

        <ng-template #elseLogoWeb>
          <a class="navbar-brand ps-3" [routerLink]="posicionamiento.UrlInicio">
            <img src="assets/logo-menu.png" alt="Logo de la empresa" title="Logo de la empresa" height="70px">
          </a>
        </ng-template>



        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                (click)="toggleNavbar()"
                data-bs-target="#navbarNavAltMarkup1" aria-controls="navbarNavAltMarkup1" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarNavAltMarkup1" [class.show]="!isNavbarCollapsed">
          <ul class="navbar-nav d-flex justify-content-center w-100">
            <ng-container *ngIf="companyConf.companyException != 'DIAZ' && superGroups != undefined; else elseSuperGrupos">
              <li *ngFor="let grupo of gruposDisponiblesMenu()" class="nav-item active border-bottom-gray mx-2"
                  (click)="toggleNavbar()">
                <a class="nav-link text-white" [routerLink]="redirectToGrupo(grupo.name)"
                   [queryParams]="{ superGrupo: 'none', grupo: grupo.id }"
                   *ngIf="grupo.id != '' && grupo.id != 'of'"
                   [ngClass]="{'fa-1em': companyConf.companyException != 'ENJA'}">
                  {{grupo.name.toUpperCase()}}
                </a>
              </li>
            </ng-container>

            <ng-template #elseSuperGrupos>
              <li *ngFor="let superGroup of superGroups" class="nav-item active border-bottom-gray mx-2"
                  (click)="toggleNavbar()">
                <a class="nav-link text-white" [routerLink]="redirectToGrupo(superGroup.name)"
                   [queryParams]="{ superGrupo: superGroup.id, grupo: '*' }"
                   *ngIf="superGroup.id != '' && superGroup.id != 'of'"
                   [ngClass]="{'fa-1em': companyConf.companyException != 'ENJA'}">
                  {{superGroup.name.toUpperCase()}}
                </a>
              </li>
            </ng-template>


            <li class="nav-item active border-bottom-gray mx-2" (click)="toggleNavbar()">
              <a class="nav-link text-white" routerLink="/compra/buscar"
                 [ngClass]="{'fa-1em': companyConf.companyException != 'ENJA'}">
                BUSCAR ARTÍCULOS
              </a>
            </li>
          </ul>

          <ul class="navbar-nav  my-2 my-lg-0">
            <li class="nav-item border-bottom-gray mx-2" *ngIf="posicionamiento.UrlAcercaDe != undefined && posicionamiento.UrlAcercaDe != ''">
              <div *ngIf="!posicionamiento.UrlAcercaDe.startsWith('/'); else elseAcercaDeWeb">
                <a class="nav-link text-white " style="min-width: 55px !important;" [href]="posicionamiento.UrlAcercaDe">
                  <i class="fa-solid fa-shop fa-2x superior-990"></i>
                  <span class="tablet-990">ACERCA DE</span>
                </a>
              </div>

              <ng-template #elseAcercaDeWeb>
                <a class="nav-link text-white " style="min-width: 55px !important;" [routerLink]="posicionamiento.UrlAcercaDe">
                  <i class="fa-solid fa-shop fa-2x superior-990"></i>
                  <span class="tablet-990">ACERCA DE</span>
                </a>
              </ng-template>
            </li>

            <li class="nav-item border-bottom-gray mx-2" *ngIf="posicionamiento.UrlContacto != undefined && posicionamiento.UrlContacto != ''">
              <div *ngIf="!posicionamiento.UrlContacto.startsWith('/'); else elseContactoWeb">
                <a class="nav-link text-white " style="min-width: 55px !important;" [href]="posicionamiento.UrlContacto">
                  <i class="fa-solid fa-headset fa-2x superior-990"></i>
                  <span class="tablet-990">CONTACTO</span>
                </a>
              </div>

              <ng-template #elseContactoWeb>
                <a class="nav-link text-white " style="min-width: 55px !important;" [routerLink]="posicionamiento.UrlContacto">
                  <i class="fa-solid fa-headset fa-2x superior-990"></i>
                  <span class="tablet-990">CONTACTO</span>
                </a>
              </ng-template>
            </li>

            <li class="nav-item border-bottom-gray mx-2">
              <a routerLink="/carrito" class="nav-link text-white"
                 style="min-width: 70px !important;"
                 (click)="activateMobil = 1; processOrderNav=false;">
                <i class="fas fa-shopping-cart fa-2x superior-990"></i>
                <span class='badge lblCartCount me-2 superior-990'> {{numberArticles}} </span>
                <span class="tablet-990">IR AL CARRITO</span>
              </a>
            </li>

            <li class="nav-item border-bottom-gray mx-2" *ngIf="!isUserAdmin">
              <a class="nav-link text-white pe-5" routerLink="/iniciarSesion" (click)="topPage()" *ngIf="!isUser">
                <i class="fas fa-user me-2 fa-2x superior-990"></i>
                <span class="tablet-990">INICIAR SESIÓN</span>
              </a>

              <a class="nav-link text-white" routerLink="/miPerfil" (click)="topPage()" *ngIf="isUser">
                <i class="fas fa-user me-2 fa-2x superior-990"></i>
                <span class="tablet-990">MI CUENTA</span>
              </a>
            </li>

            <li class="nav-item border-bottom-gray mx-2" *ngIf="isUserAdmin">
              <a class="nav-link text-white pe-5" routerLink="/iniciarSesion" (click)="logOut()">
                <i class="fa-solid fa-right-from-bracket me-2 fa-2x superior-990"></i>
                <span class="tablet-990">CERRAR SESIÓN</span>
              </a>
            </li>

            <!--<li class="nav-item">
              <a class="nav-link text-white">
                <div id="google_translate_element"></div>
              </a>
            </li>-->
          </ul>
        </div>
      </nav>

      <ng-template #ordenador3>
        <nav class="w-100 navbar-dark bg-secondary mb-0">
          <div class="container-fluid"
               style="padding-left: 15px !important; padding-right: 15px !important">
            <div class="row">
              <div class="col-md-2">
                <a class="navbar-brand"  routerLink="/">
                  <img id="logo4" src="assets/logo-menu.png" alt="Logo de la empresa" title="Logo de la empresa" height="70px">
                </a>
              </div>

              <div class="col-md-8">
                <ul class="w-100 h-100 d-flex justify-content-center align-items-center list-unstyled">
                  <li *ngFor="let grupo of superGroups[0].groups" class="nav-item active border-bottom-990">
                    <a class="nav-link text-white" [routerLink]="redirectToGrupo(grupo.name)"
                       [queryParams]="{ superGrupo: 'none', grupo: grupo.id }"
                       *ngIf="grupo.id != '' && grupo.id != 'of'">
                      {{grupo.name[0].toUpperCase()}}{{grupo.name.substring(1,grupo.name.length).toLowerCase()}}
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-md-2">
                <ul class="h-100 d-flex justify-content-end align-items-center list-unstyled">
                  <li class="nav-item border-bottom-990">
                    <a class="nav-link text-white" routerLink="/compra/buscar">
                      <i class="fas fa-search me-1"></i>
                      <span class="tablet-990">BUSCAR ARTÍCULOS</span>
                    </a>
                  </li>

                  <li class="nav-item border-bottom-990">
                    <a routerLink="/carrito" class="nav-link text-white"
                       (click)="activateMobil = 1; processOrderNav=false;">
                      <i class="fas fa-shopping-cart"></i>
                      <span class='badge badge-warning lblCartCount me-2'> {{numberArticles}} </span>
                      <span class="tablet-990">IR AL CARRITO</span>
                    </a>
                  </li>

                  <li class="nav-item border-bottom-990">
                    <a class="nav-link text-white" routerLink="/iniciarSesion" (click)="topPage()" *ngIf="!isUser">
                      <i class="fas fa-right-to-bracket me-1"></i>
                      <span class="tablet-990">INICIAR SESIÓN</span>
                    </a>

                    <a class="nav-link text-white" routerLink="/miPerfil" (click)="topPage()" *ngIf="isUser">
                      <i class="fas fa-user me-1"></i>
                      <span class="tablet-990">MI CUENTA</span>
                    </a>
                  </li>

                  <!--<li class="nav-item">
                    <a class="nav-link text-white">
                      <div id="google_translate_element"></div>
                    </a>
                  </li>
                  -->
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </ng-template>
    </div>
    <!-- -------- FIN NAVBAR PARTICULARES --------- -->


    <!--<div *ngIf="companyConf.msgWeb" class="max border-bottom border-top border-primary text-light text-center">
      {{companyConf.msgWeb}}
    </div>
    -->

    <div id="border-bottom-nav"></div>


    <div class="navbar-mobil bottom-menu" [hidden]="!this.showBottomMenu">
      <div class="bg-white d-flex" style="height: 80px !important;">
        <a [routerLink]="'/'" style="width: 33.33% !important;" #init>
          <div>
            <i class="fas fa-home"></i><br />
            Inicio
          </div>

          <div *ngIf="activateMobil != null">
            {{selectMenuActive(init, car, profile)}}
          </div>
        </a>

        <a routerLink="/carrito" style="width: 33.33% !important;" #car
           (click)="activateMobil = 1; processOrderNav=false;">
          <i class="fas fa-shopping-cart"></i><span class='badge badge-warning lblCartCount'> {{numberArticles}}
          </span><br />
          Carrito
        </a>

        <a style="width: 33.33% !important;" #profile>
          <ng-container *ngIf="userInfo; else elseSession">
            <div routerLink="/miPerfil" class="w-100 h-100">
              <i class="fas fa-user"></i><br />
              Mi perfil
            </div>
          </ng-container>

          <ng-template #elseSession>
            <span routerLink="/iniciarSesion">
              <i class="fas fa-sign-in-alt"></i><br />
              Acceder
            </span>
          </ng-template>
        </a>
      </div>
    </div>


    <!-- Main content -->
    <main class="main" style="min-height: 70vh !important;">
      <div *ngIf="companyConf != undefined ">
        <router-outlet (activate)="onChildLoaded($event)"></router-outlet>
      </div>
    </main>

    <!-- Footer -->
    <footer *ngIf="companyConf.companyException != 'ENJA'; else footerEntrevetas" class="page-footer font-small pt-2 " [ngClass]="{'bg-dark': companyConf.companyException != 'PEMA','bg-darkslategrey': companyConf.companyException == 'PEMA'}">
      <div class="pt-4 ps-2 pe-2" style="width: 90%; margin-left: 5%; margin-right: 5%;">
        <!-- Grid -->
        <div *ngIf="companyConf" class="row">
          <!-- Content -->
          <div class="col-md-6 pb-5 menu-text">
            <div>
              <p style="font-size: 1.5em" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-black': companyConf.companyException == 'PEMA'}">
                {{datosLegalesEmpresa.name.replace('ESPAÑA, ', '')}}</p>
              <div [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">
                <span>
                  {{datosLegalesEmpresa.direction}}<br>
                  Telf: {{companyConf.phone}} <span class="ms-3">{{companyConf.alternativePhone}}<br>
                  </span>
                  Correo electrónico:<br>
                  <a href="mailto:{{datosLegalesEmpresa.email}}" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">{{datosLegalesEmpresa.email}}</a>
                </span>
              </div>
            </div>
          </div>

          <!-- Links -->
          <div class="col-md-6 pb-5 menu-text">
            <div>
              <p class="font-weight-bold" style="font-size: 1.25em" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-black': companyConf.companyException == 'PEMA'}">Archivos de Interés</p>
              <ul class="list-unstyled" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">
                <li>
                  <a routerLink="/aviso-legal" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Aviso Legal</a>
                </li>
                <li>
                  <a routerLink="/politica-privacidad" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Política de privacidad</a>
                </li>
                <li>
                  <a routerLink="/política-devolucion-y-envio"
                     [ngClass]="{'text-white': companyConf.companyException != 'PEMA', 'text-dark': companyConf.companyException == 'PEMA'}">
                    Política de devolución y envío
                  </a>
                </li>
                <li>
                  <a routerLink="/aviso-cookies" [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}">Cookies</a>
                </li>
                <li>
                  <a [ngClass]="{'text-white': companyConf.companyException != 'PEMA',
                                'text-dark': companyConf.companyException == 'PEMA'}"
                     (click)="openExternalLink('https://www.soft-control.net')">
                    Desarrollado por Soft Control Informática
                  </a>
                </li>

                <li *ngIf="companyConf.companyException == 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
                  <div>
                    <img src="assets/logos-especiales/UnionEuropea.png" alt="Fondo Europeo de Desarrollo Regional"
                         style="width: 100px !important; height: auto !important;" class="me-2">
                    <img src="assets/logos-especiales/JuntaAndalucia.png" alt="Junta de Andalucía"
                         style="width: 100px !important; height: auto !important;" class="me-2">
                  </div>
                </li>
                <li *ngIf="companyConf.companyException == 'PEMA'" class="mt-2 w-100 d-flex justify-content-center">
                  <div>
                    <img src="assets/logos-especiales/andaluciaSeMueve.png" alt="Andalucía se mueve con Europa"
                         style="width: 100px !important; height: auto !important;">
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="container" *ngIf="companyConf.subvencionadoKitDigital">
            <div class="row justify-content-center" style="margin-bottom: 100px !important;" >
              <div class="col-md-4 col-12 mt-3" >
                <div class="d-flex justify-content-center">
                  <img src="assets/logos-especiales/LOGOPOSITIVO.png" class="bg-white h-logo-footer"
                       alt="Plan de recuperación, transformación y resilencia" width="auto">
                </div>
              </div>

              <div class="col-md-6 col-12 mt-3">
                <div class="d-flex justify-content-center">
                  <img src="assets/logos-especiales/Financiado_UE.png" class="bg-white h-logo-footer"
                       alt="Financiado por la UE"  width="auto">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Grid-->
    </footer>
    <!-- Footer -->

    <ng-template #footerEntrevetas>
      <footer class="w-100 bg-primary text-light">
        <div class="pt-5 pb-3">
          <div class="w-100">
            <div class="d-flex justify-content-center">
              <img width="300" height="auto"
                   src="assets/logo-footer.png"
                   class="elementor-animation-rotate attachment-large size-large"
                   alt="Logo de la empresa" title="Logo de la empresa">
            </div>

            <div class="mb-3 w-100 text-center text-light pt-3" style="font-size: 1.2rem">
              <a class="text-decoration-none text-light" href="https://entrevetasjamoneria.com/contacto/">Contacto</a>
              | <a class="text-decoration-none text-light" href="https://entrevetasjamoneria.com/aviso-legal/" rel="noopener">Aviso legal</a>
              | <a class="text-decoration-none text-light" href="https://entrevetasjamoneria.com/privacidad/" rel="noopener">Política de privacidad</a>
              | <a class="text-decoration-none text-light" href="https://entrevetasjamoneria.com/cookies/" rel="noopener">Cookies</a>
              | <a class="text-decoration-none text-light" href="/política-devolucion-y-envio" rel="noopener">Política de devoluciones y envío</a>
            </div>

            <div class="mb-3 w-100 text-center  pt-3" style="font-size: 2rem">
              <a class="me-4 text-light" href="https://www.facebook.com/Entrevetas.cortadoresdejamon/">
                <i aria-hidden="true" class="fab fa-facebook-f"></i>
              </a>

              <a class="text-light" href="https://www.instagram.com/entrevetasjamoneria/">
                <i aria-hidden="true" class="fab fa-instagram"></i>			</a>
            </div>

            <div class="mb-3 w-100 text-center pt-3 pb-3" style="font-size: 1.2rem">
              <a class="text-decoration-none text-light" href="https://soft-control.net">
                Desarrollado por Soft Control Informática
              </a>
            </div>
          </div>
        </div>
      </footer>
      <div class="w-100 text-center bg-secondary text-white pt-3 pb-3" style="font-size: 1.2rem">
        © Entrevetas · 2023 ·
      </div>
    </ng-template>
  </div>

  <div *ngIf="companyConf != undefined">
    <div class="position-sticky vh-100 w-100 bg-primary text-light" [hidden]="!showMenu"
         *ngIf="companyConf.tipoMenu == 2">
      <div class="d-flex align-items-center p-4">
        <div class="w-100">
          <div class="mb-2 border-bottom border-light w-100 text-end" (click)="showMenu = false">
            <i class="fa-solid fa-xmark fa-2x pb-3"></i>
          </div>

          <div>
            <h2 class="nav-item nav-link text-light" routerLink="/" (click)="showMenu = false">Inicio</h2>
            <h2 class="nav-item nav-link text-light text-decoration-none text-black" [routerLink]="menu2[0].ruta" (click)="showMenu = false">
              <h2>{{menu2[0].texto}}</h2>
            </h2>
            <a class="nav-item nav-link text-light text-decoration-none text-black" [routerLink]="menu2[1].ruta" (click)="showMenu = false">
              <h2>{{menu2[1].texto}}</h2>
            </a>
            <a class="nav-item nav-link text-light text-decoration-none text-black" [routerLink]="menu2[2].ruta" (click)="showMenu = false">
              <h2>{{menu2[2].texto}}</h2>
            </a>
            <a class="nav-item nav-link text-light text-decoration-none text-black" [routerLink]="menu2[3].ruta" (click)="showMenu = false">
              <h2>{{menu2[3].texto}}</h2>
            </a>
            <a class="nav-item nav-link text-light text-decoration-none text-black" routerLink="/atencion-al-cliente" fragment="ubicaciones"
                (click)="showMenu = false">Puntos de recogida</a>
            <a class="nav-item nav-link text-light" routerLink="/atencion-al-cliente" (click)="showMenu = false">
              <h2>Contacto</h2>
            </a>
            <a class="nav-item nav-link text-light text-decoration-none text-black" routerLink="/iniciarSesion" (click)="showMenu = false"
                *ngIf="!isUser; else USUARIO">
              <h2>Entrar</h2>
            </a>
            <ng-template #USUARIO>
              <a class="nav-item nav-link text-light" routerLink="/miPerfil" (click)="showMenu = false">
                <h2>Mi Perfil</h2>
              </a>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
