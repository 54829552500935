<div [ngClass]="{'tablet-990': companyException != 'AVES'}">
  <div class="d-flex justify-content-center mw-100 pt-1 ">
    <div class="scrolling-wrapper p-0 m-0" #list style="width: 92% !important;">
      <div *ngFor="let superGroup of superGroups" class="d-flex">
        <a *ngFor="let group of superGroup.groups">
          <div *ngIf="group.id == 'of'; else GRUPO_T">
            <div class="container-img center">
              <img src="assets/fotos/grupos/ofertas.webp" alt="Ofertas"  title="Ofertas"
                   [ngClass]="{'img': companyException != 'ENJA', 'img-enja': companyException == 'ENJA'}"/>
            </div>

            <div class="tipo-nombre" *ngIf="companyException != 'ENJA' && companyException != 'AVES'">{{group.name}}</div>
          </div>

          <ng-template #GRUPO_T>
            <div class="tipo-comida" *ngIf="group.id != ''">
              <a class="container-img center pointer"
                 [routerLink]="getArticlesGroup(group)"
                 [queryParams]="{superGrupo: ((superGroup.id == '') ? 'none' : superGroup.id), grupo: group.id}">
                <img src="{{group.groupImgUrl != '' ?
                    'assets/fotos/grupos/' + group.groupImgUrl : 'assets/fotos/grupos/' + group.id + '.webp'}}"
                     alt="Imagen {{group.name}}"  title="Imagen {{group.name}}"
                     height="90" width="90"/>
              </a>
            </div>
          </ng-template>
        </a>
      </div>
    </div>

  </div>
</div>

<div class="superior-990" *ngIf="companyException != 'AVES'">
  <div class="w-100 pt-1">
    <div class="p-0 m-0 d-flex justify-content-center w-100">
      <div *ngFor="let superGroup of superGroups" class="d-flex justify-content-center">
        <div *ngFor="let group of superGroup.groups">
          <div *ngIf="group.id == 'of'; else GRUPO_T">
            <div class="center">
              <img src="assets/fotos/grupos/ofertas.webp" alt="Ofertas" width="200px" height="auto"/>
            </div>
          </div>

          <ng-template #GRUPO_T>
            <div style="margin-right: 5px; " *ngIf="group.id != ''">
              <a [routerLink]="getArticlesGroup(group)"
                                  [queryParams]="{superGrupo: ((superGroup.id == '') ? 'none' : superGroup.id), grupo: group.id}">
                <img src="{{group.groupImgUrl != '' ? 'assets/fotos/grupos/' + group.groupImgUrl : 'assets/fotos/grupos/' + group.id + '.webp'}}"
                     width="140px" height="auto"
                     alt="Imagen {{group.name}}" class="img-computer"/>
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
