import {
  Component, ElementRef, HostListener, Inject,
  OnInit, PLATFORM_ID, QueryList, Renderer2, ViewChildren,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Article, ArticleCookie, BasicArticleQuantity} from '../../../interfaces/article';
import {DefaultService} from '../../../services/default.service';
import {SuperGroup} from '../../../interfaces/group';
import {Family} from '../../../interfaces/family';
import {CompanyConf} from '../../../interfaces/company-conf';
import {PriceRoute} from '../../../interfaces/price-route';
import {LocalStorageService} from '../../../services/local-storage.service';
import {ShoppingCarService} from '../../../services/shopping-car.service';
import {cloneDeep} from 'lodash';
import {NumberInterface} from '../../../interfaces/numberInterface';
import {CarArticle} from '../../../interfaces/car-article';
import {Store} from '@ngrx/store';
import {AuthStorageService} from '../../../services/auth/auth-storage.service';
import {obtenerArticulosInfo, obtenerEmpresaInfoTotal, obtenerFamilias, obtenerSuperGrupo} from '../../../../redux/empresa.actions';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {WebEstaticaService} from '../../../services/web-estatica/web-estatica.service';
import {getWindow} from 'ssr-window';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private defaultService: DefaultService,
              private authStorageService: AuthStorageService, private titleService: Title,
              private metaService: Meta, @Inject(DOCUMENT) private _document: Document,
              private localStorage: LocalStorageService, private storeEmpresa: Store<any>, private storeArticulos: Store<any>,
              private webEstaticaService: WebEstaticaService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private shoppingCarService: ShoppingCarService, private renderer: Renderer2) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.route.queryParams.subscribe(params => {
      this.groupId = params['grupo'];
      this.superGroupId = params['superGrupo'];
    });

    router.events.subscribe((val) => {
      if (this.urlActual != this.router.url) {
        this.urlActual = this.router.url;
        this.route.queryParams.subscribe(params => {
          this.groupId = params['grupo'];
          this.superGroupId = params['superGrupo'];
        });
            this.articles = [];
        this.actualizarNombreNavegacion();
        if (this.superGroups != undefined && this.articles != undefined) {
          this.cargarPosicionarmiento();
        }
        this.obtenerArticulos();
      }
    });

  }
  isBrowser = false;


  @ViewChildren('myDivs') myDivs: QueryList<ElementRef>;
  urlActual = "";
  superGroups: Array<SuperGroup>;
  inputFamilies: Array<Family>;
  minPrice: number;
  companyConf: CompanyConf;
  paymentMethod: string;
  priceRoute: PriceRoute;
  selectKgs;
  selectUnits;
  indicateLumps;
  isUser;
  homeDelivery: boolean;
  numberArticles: Function;
  numberOfArticles: number;
  posicionamiento: any;

  families: Array<Family> = [];
  superGroup: SuperGroup;
  articles: Array<Article> = [];
  articlesCopy: Array<Article>  = [];
  formatNames: Array<string> = [];
  pageArticles = 1;
  numberArticlesPage = 40;
  numberArticlesPageCopy = 40;
  numberPage = 1;
  groupId: string;
  superGroupId: string;
  articuloPulsado: string = "";
  offers = false;
  showOffersFilter = false;
  searchWindow = false;

  // Price options
  readyArticles = false;
  value = 0;
  options: any;

  // Filter
  filterFormat = [];
  filterFamilies = [];
  filterOffer = false;
  order = 'name:asc';
  showFamilies = false;
  showFormats = false;
  showFilterMobil = false;
  familiesOccult = true;
  groupName: string;

  searchDefaultText = 'Buscar en ofertas';

  verTresArticulosOrdenador = false;


  ngOnInit(): void {
    this.isUser = this.authStorageService.getUser() != null;
    this.obtenerDatosEmpresa();
    this.obtenerFamilias();
    this.obtenerSuperGrupos();
  }

  urlImg(pos: number, url: string): string {
    if (pos == 1) {
      return this.unirCadenasDespuesDelUltimoPunto(url, '_1') ;
    }

    return url ;
  }

  setCarrouselImgNext(exists: boolean, id: string) {
    if (exists) {
      // @ts-ignore
      document.getElementById('buttonNext' + id).click();
    }
  }

  unirCadenasDespuesDelUltimoPunto(cadena1: string, cadena2: string): string {
    const ultimoPuntoIndex = cadena1.lastIndexOf('.');
    if (ultimoPuntoIndex != -1) {
      return cadena1.substring(0, ultimoPuntoIndex)  + cadena2 +  cadena1.substring(ultimoPuntoIndex);
    } else {
      return cadena1 + cadena2;
    }
  }

  defaultTextSearch() {
    if (this.offers) {
      this.searchDefaultText = 'Buscar en ofertas';
    } else if (this.searchWindow) {
      this.searchDefaultText = 'Buscar en todos los productos';
    } else if (this.superGroup) {
      if ((this.groupId != '*' && this.superGroup.groups.length > 1) || this.superGroupId == 'none') {
        this.searchDefaultText = 'Buscar en ' + this.groupName;
      } else {
        this.searchDefaultText = 'Buscar en ' + this.superGroup.name;
      }
    }
  }

  pageChanged(event) {
    this.pageArticles = event;
    if (this.pageArticles == this.numberPage) {
      this.numberArticlesPageCopy = this.articles.length - (this.numberPage - 1) * this.numberArticlesPage;
    } else {
      this.numberArticlesPageCopy = this.numberArticlesPage;
    }
  }

  // _________________________ REDIRECCIONAR _____________________________
  redirectToGrupo(nombre: string) {
    return '/compra/' + nombre.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
  }

  redirectToArticulo(nombreGrupo: string, nombreArticulo: string) {
    nombreArticulo = nombreArticulo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    nombreGrupo = nombreGrupo.toLowerCase().split(' ').join('-').split('(').join('').split(')').join('').split('/').join('');
    return '/compra/' + nombreGrupo + '/' + nombreArticulo;
  }

  // _________________________ FILTROS _____________________________
  filterFormatFn(arg) {
    const format = arg.target.value;
    if (this.filterFormat.find(e => e == format) == null) {
      this.filterFormat.push(format);
    } else {
      this.filterFormat = this.filterFormat.filter(element => element != format);
    }
    this.filter();
  }

  filterFamilyFn(arg) {
    const family = arg.target.value;
    if (this.filterFamilies.find(e => e == family) == null) {
      this.filterFamilies.push(family);
    } else {
      this.filterFamilies = this.filterFamilies.filter(element => element != family);
    }
    this.filter();
  }

  filterOfferFn() {
    this.filterOffer = !this.filterOffer;
    this.filter();
  }

  orderArticles(id) {
    this.order = id.value;
    this.filter();
  }

  filter() {
    this.pageArticles = 1;
    let articles1: Array<Article> = [];
    if (this.filterFormat.length == 0) {
      articles1 = this.articlesCopy;
    } else {
      for (const format of this.filterFormat) {
        for (const article of this.articlesCopy) {
          if (article.realFormatName == format) {
            articles1.push(article);
          }
        }
      }
    }

    let articles2: Array<Article> = [];
    if (this.filterFamilies.length == 0) {
      articles2 = articles1;
    } else {
      for (const family of this.filterFamilies) {
        for (const article of articles1) {
          if (article.family == family) {
            articles2.push(article);
          }
        }
      }
    }

    let articles3: Array<Article>;
    if (this.filterOffer) {
      articles3 = articles2.filter(e => e.offer == 1);
    } else {
      articles3 = articles2;
    }

    if (this.order == 'price:asc') { // mas baratos primero
      articles3.sort(function(a, b) {return a.price - b.price;});
    } else if (this.order == 'price:desc') {
      articles3.sort(function(a, b) {return a.price - b.price;});
      articles3.reverse();
    } else if (this.order == 'name:asc') {
      articles3.sort((a, b) => a.name.localeCompare(b.name));
    } else if (this.order == 'name:desc') {
      articles3.sort((a, b) => a.name.localeCompare(b.name));
      articles3.reverse();
    }

    this.articles = articles3;
  }

  showFamiliesFn() {
    this.showFamilies = !this.showFamilies;
  }

  showFormatsFn() {
    this.showFormats = !this.showFormats;
  }

  showFilterMobilFn() {
    this.showFilterMobil = !this.showFilterMobil;
  }

  familySelected(arg): boolean {
    return this.filterFamilies.find(e => e == arg) != null;
  }

  formatSelected(arg): boolean {
    return this.filterFormat.find(e => e == arg) != null;
  }

  stopPropagation(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }

  newArticleNumber(number) {
    this.numberArticles(number);
  }

  addQuantity(format: number, article: Article) {
    if (article.quantity == undefined) {
      article.quantity = (format == 0) ? 1 : 0.5;
    } else {
      if (this.selectKgs != undefined && this.selectUnits != undefined && this.selectKgs.length > 0 && this.selectUnits.length > 0) {
        const pos = (((format == 1) ? this.selectKgs : this.selectUnits)).findIndex((el: number) => el > ((article.lumps > 0 && article.approxWeight > 0) ? article.lumps : article.quantity));

        if (pos != -1) {
          if (article.format == 2 && article.quantity > 0 && article.approxWeight > 0) {
            article.lumps = (((format == 1) ? this.selectKgs : this.selectUnits))[pos];
            article.quantity = article.lumps * article.approxWeight;
          } else {
            article.quantity = (((format == 1) ? this.selectKgs : this.selectUnits))[pos];
          }
        } else {
          if (article.format == 2 && article.quantity > 0 && article.approxWeight > 0) {
            article.lumps = 1;
            article.quantity = article.approxWeight;
          } else {
            article.quantity = 1;
          }
        }
      } else {
        if (article.format == 2 && article.quantity > 0 && article.approxWeight > 0) {
          article.lumps = (format == 0) ? + 1 : + 0.5;
          article.quantity = article.lumps * article.approxWeight;
        } else {
          article.quantity = (format == 0) ? + 1 : + 0.5;
        }
      }
    }
  }

  subtractQuantity(format: number, article: Article) {
    if (article.quantity == undefined) {
      article.lumps = 0;
      article.quantity = 0;
    } else {
      if (this.selectKgs != undefined && this.selectUnits != undefined && this.selectKgs.length > 0 && this.selectUnits.length > 0) {
        let reverseArray = cloneDeep((format == 1) ? this.selectKgs : this.selectUnits);
        reverseArray.reverse();
        const pos = reverseArray.findIndex((kg: number) => kg < ((article.lumps > 0 && article.approxWeight > 0) ? article.lumps : article.quantity));
        if (pos != -1) {
          if (article.format == 2 && article.quantity > 0 && article.approxWeight > 0) {
            article.lumps = reverseArray[pos];
            article.quantity = reverseArray[pos] * article.approxWeight;
          } else {
            article.quantity = reverseArray[pos];
          }
        } else {
          article.quantity = 0;
        }
      } else {

        if (article.format == 2 && article.quantity > 0 && article.approxWeight > 0) {
          article.lumps = (format == 0 && article.quantity > 0) ? -1 : (article.quantity > 0.5) ? -0.5 : 0;
          article.quantity = article.lumps * article.approxWeight;
        } else {
          article.quantity = (format == 0 && article.quantity > 0) ? -1 : (article.quantity > 0.5) ? -0.5 : 0;
        }
      }
    }
  }

  setQuantity(article: Article) { // CAMBIAR
    if (!this.isUser) {
      this.localStorage.removeFruitArticle(article.id);
    }

    let quantity = 0;
    if (article.quantity > 0) {
      this.articuloPulsado = cloneDeep(article.id);
      quantity = parseFloat(String(article.quantity));

      setTimeout(() => {
        this.articuloPulsado = "";
      }, 500);
    }

    const arg: ArticleCookie = new ArticleCookie(article.id, article.line, article.name, article.format,
      null, article.price, article.nameClient, article.group,
      article.family, quantity, article.lumps,
      article.totalPriceIva, article.observations, null, article.cutName);

    if ((quantity == undefined || quantity == 0) && this.isUser) {
      this.shoppingCarService.deleteArticle(article.id, article.line, '').subscribe(
        () => {
          this.defaultService.getNumberOfArticles().subscribe(
            (result: NumberInterface) => {
              this.newArticleNumber(result.value);
            }
          );
        }
      );
    } else {
      if (!this.localStorage.addLocalStorageArticle(arg) && article.line > 0) {
        this.shoppingCarService.setQuantity(article.id, article.line, parseFloat(String(article.quantity)), article.lumps, article.format).subscribe();
      } else if ( article.line == 0 && this.isUser) {
        this.shoppingCarService.addArticle(article.id, article.format, arg.quantity, arg.lumps, arg.cutType, false).subscribe(
          () => {
            this.defaultService.getNumberOfArticles().subscribe(
              (result: NumberInterface) => {
                this.newArticleNumber(result.value);
              }
            );
          }
        );
      }
    }

    if (!this.isUser) {
      this.numberOfArticlesLocal();
    }
  }

  numberOfArticlesLocal() {
    const arts = this.localStorage.getLocalStorageArticles();
    let articlesCookies: Array<CarArticle> = null;
    if (arts != null && Array.isArray(arts)) {
      articlesCookies = arts;
    } else if (arts != null) {
      articlesCookies = [arts];
    }

    if (articlesCookies != null) {
      let articlesStr = '';
      articlesCookies.forEach( e => {articlesStr += "'" + e.id + "'" + ','});
      articlesStr = articlesStr.substr(0, articlesStr.length - 1);
      if (articlesStr != '') {
        this.shoppingCarService.getPendingArticles(articlesStr, null).subscribe(
          (data: Array<CarArticle>) => {
            if (data != undefined) {
              this.newArticleNumber(data.length);
            } else {
              this.newArticleNumber(0);
            }
          }
        );
      } else {
        this.newArticleNumber(0);
      }
    } else {

      this.newArticleNumber(0);
    }

  }


  obtenerInfoInicialArticulos(data: any) {
    this.urlActual = this.router.url;
    if (this.isUser) {
      this.shoppingCarService.getPendingArticles(null, null).subscribe(
        (data1: Array<CarArticle>) => {
          let quantities: Array<BasicArticleQuantity> = [];
          data1.forEach(el => {
            quantities.push({id: el.id, line: el.line, quantity: el.quantity})
          });

          if (quantities.length > 0) {
            if (data != undefined) {
              data.forEach(el =>  {
                let art = quantities.find(a => a.id == el.id);
                if (art != undefined) {
                  if(el.id == art.id) {
                    el.quantity = art.quantity;
                    el.line = art.line;
                  }
                }
              });
            }
          }

          this.inicializarArticulos(data);
        }
      );
    } else {
      const arts = this.localStorage.getLocalStorageArticles();
      if (arts != undefined) {
        data.forEach(el =>  {

          let art = undefined;
          if (arts instanceof Array) {
            art = arts.find(a => a.id == el.id);
          } else {
            art = arts;
          }

          if (art != undefined) {
            if(el.id == art.id) {
              el.quantity = parseFloat(art.quantity);
            }
          }
        });
      }

      this.inicializarArticulos(data);
    }
  }


  checkIfImageExists(url: string, pos: number) {
    const img = new Image();
    img.src = './' + url;

    if (this.articles != undefined) {
      if (img.complete) {
        this.articles[pos].search = true;
      } else {
        img.onload = () => {
          this.articles[pos].search = true;
        };
      }
    }
  }

  inicializarArticulos(data) {
    if (this.router.url == '/compra/ofertas') {
      this.offers = true;
      data = data.filter(el => el.offer = true);
      this.inputFamilies = this.families.filter(el => data.findIndex(ar => ar.family == el.id) != -1);
    } else if (this.router.url == '/compra/buscar') {
      this.searchWindow = true;
      this.inputFamilies = this.families;
    } else {
      if (this.groupId != '*') {
        data = data.filter(el =>
          ((el.group == 'none' || el.group == '') && this.groupId == 'none') ? true : el.group == this.groupId
        );
      }
      if (this.superGroupId != 'none') {
        data = data.filter(el =>
          ((el.superGroup == 'none' || el.superGroup == '') && (this.superGroupId == 'none')) ? true : el.superGroup == this.superGroupId
        );
      }

      this.inputFamilies = this.families.filter(el =>
        ((el.group == 'none' || el.group == '') && this.groupId == 'none') ? true : el.group == this.groupId
      );

      this.inputFamilies = this.families.filter(el =>
        ((el.superGroup == 'none' || el.superGroup == '') && this.superGroupId == 'none') ? true : el.superGroup == this.superGroupId
      );

    }

    data.forEach(el => {
      if (el.articleImgUrl == null || el.articleImgUrl == '') {
        el.articleImgUrl = 'generica.webp'
      }
    });
    this.articles = data;
    this.articlesCopy = data;
    for (let i = 0; i<this.articles.length; i++) {this.checkIfImageExists('assets/fotos/articulos/size-s/' + this.urlImg(1, this.articles[i].articleImgUrl).split(' ').join('%20'), i)}
    this.defaultTextSearch();
    this.setEqualHeight();
    setTimeout(() => {
      this.setEqualHeight();
    }, 100);
  }


  setEqualHeight(): void {
    if (this.myDivs != undefined) {
      // Initialize a variable to store the maximum height
      let maxHeight = 0;

      // Loop through each ElementRef in myDivs
      this.myDivs.forEach(divRef => {
        const divHeight = divRef.nativeElement.clientHeight;

        // Update maxHeight if the current div is taller
        if (divHeight > maxHeight) {
          maxHeight = divHeight;
        }
      });

      // Apply the maximum height to all divs
      if (maxHeight > 0) {
        this.myDivs.forEach(divRef => {
          this.renderer.setStyle(divRef.nativeElement, 'height', maxHeight + 'px');
        });
      }
    }
  }

  cargarPosicionamientoOfertas() {
    let keyWords = 'ofertas,descuentos,promociones,mejores ofertas,precios bajos,' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(this.companyConf.webName + ' | Ofertas de productos');

    this.metaService.addTag({
      name: 'description', content: '¡Descubre nuestra amplia selección de productos en oferta de alta calidad! ' +
          ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.'
    });
    this.metaService.addTag({name: 'keywords', content: keyWords});
    this.metaService.addTag({name: 'og:site_name', content: this.posicionamiento.Name});
    this.metaService.addTag({name: 'og:locale', content: this.posicionamiento.Locale});
    this.metaService.addTag({name: 'robots', content: 'index, follow'});
    this.metaService.addTag({name: 'googlebot', content: 'index, follow'});
    this.metaService.addTag({name: 'autor', content: this.posicionamiento.Author});
  }

  cargarPosicionarmiento() {
    this.limpiarPosicionamiento();
    if (this.router.url.includes('/compra/ofertas')) {
      this.cargarPosicionamientoOfertas();
    } else if (this.router.url.includes('/compra/buscar')) {
      this.cargarPosicionamientoBuscar();
    } else if (this.router.url.includes('/compra')) {
      this.cargarPosicionamientoArticulos();
    }
  }

  cargarPosicionamientoBuscar() {
    let keyWords = 'buscar,buscar todos,variedad,' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(this.companyConf.webName + ' | Buscar productos');

    this.metaService.addTag({
      name: 'description', content: '¡Descubre nuestra amplia selección de productos de alta calidad! ' +
          ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.'
    });
    this.metaService.addTag({name: 'keywords', content: keyWords});
    this.metaService.addTag({name: 'og:site_name', content: this.posicionamiento.Name});
    this.metaService.addTag({name: 'og:type', content: 'website'});
    this.metaService.addTag({name: 'og:locale', content: this.posicionamiento.Locale});
    this.metaService.addTag({name: 'robots', content: 'index, follow'});
    this.metaService.addTag({name: 'googlebot', content: 'index, follow'});
    this.metaService.addTag({name: 'autor', content: this.posicionamiento.Author});
  }



  cargarPosicionamientoArticulos() {
    let keyWords = this.groupName + ',E-commerce,Comprar Online,' + this.posicionamiento.KeyWords;
    this.titleService.setTitle(this.groupName + ' | ' + this.posicionamiento.Title);
    this.metaService.addTag({name: 'title', content: this.groupName + ' | ' + this.posicionamiento.Title});
    this.metaService.addTag({name: 'og:title', content: this.groupName + ' | ' + this.posicionamiento.Title});

    this.metaService.addTag({
      name: 'description', content: '¡Descubre nuestra amplia selección de ' + this.groupName + ' de alta calidad! ' +
          ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.'
    });
    this.metaService.addTag({
      name: 'og:description', content: '¡Descubre nuestra amplia selección de ' + this.groupName + ' de alta calidad! ' +
        ' Realiza tu pedido hoy mismo y disfruta de nuestros productos.'
    });
    this.metaService.addTag({name: 'keywords', content: keyWords});
    this.metaService.addTag({name: 'og:type', content: 'website'});
    this.metaService.addTag({name: 'og:site_name', content: this.posicionamiento.Name});
    this.metaService.addTag({name: 'og:locale', content: this.posicionamiento.Locale});
    this.metaService.addTag({name: 'robots', content: 'index, follow'});
    this.metaService.addTag({name: 'googlebot', content: 'index, follow'});
    this.metaService.addTag({name: 'autor', content: this.posicionamiento.Author});

    let pos = this.superGroups.findIndex( el => el.groups.findIndex(el => el.id == this.groupId) != -1);
    if (pos != -1) {
      let group = this.superGroups[pos].groups[this.superGroups[pos].groups.findIndex(el => el.id == this.groupId)];
      this.metaService.addTag(
        {
          name: 'og:image:url',
          itemprop: 'image',
          content: this._document.URL.replace(this.urlActual, '').replace(/[?&][^=?&]+=[^=?&]+/g, '') +
            (group.groupImgUrl != '' ?
              '/assets/fotos/grupos/size-xs/' + group.groupImgUrl.replace('.jpeg', '.webp').replace('.png', '.webp').replace('.jpg', '.webp')
              :
              '/assets/fotos/grupos/size-xs/' + group.id + '.webp')});

      this.metaService.addTag(
        {
          name: 'og:image:secure_url',
          itemprop: 'image',
          content: this._document.URL.replace(this.urlActual, '').replace(/[?&][^=?&]+=[^=?&]+/g, '') +
            (group.groupImgUrl != '' ?
              '/assets/fotos/grupos/size-xs/' + group.groupImgUrl.replace('.jpeg', '.webp').replace('.png', '.webp').replace('.jpg', '.webp')
              :
              '/assets/fotos/grupos/size-xs/' + group.id + '.webp')});

      this.metaService.addTag({name: 'og:image:type', content: "image/webp"});
      this.metaService.addTag({name: 'og:image:width', content: "250"});
      this.metaService.addTag({name: 'og:image:height', content: "250"});

    }
  }

  limpiarPosicionamiento() {
    this.metaService.removeTag("name='og:title'");
    this.metaService.removeTag("name='title'");
    this.metaService.removeTag("name='description'");
    this.metaService.removeTag("name='og:site_name'");
    this.metaService.removeTag("name='og:url'");
    this.metaService.removeTag("name='name'");
    this.metaService.removeTag("name='author'");
    this.metaService.removeTag("name='keywords'");
    this.metaService.removeTag("name='robots'");
    this.metaService.removeTag("name='og:locale'");
    this.metaService.removeTag("name='og:type'");
    this.metaService.removeTag("name='og:image'");
    this.metaService.removeTag("name='og:image:type'");
    this.metaService.removeTag("name='og:image:url'");
    this.metaService.removeTag("name='og:image:secure_url'");
    this.metaService.removeTag("name='og:image:width'");
    this.metaService.removeTag("name='og:image:height'");
    this.metaService.removeTag("name='og:description'");
    this.metaService.removeTag("name='product:price:currency'");
    this.metaService.removeTag("name='product:price:amount'");
    this.metaService.removeTag("name='product:availability'");
  }

  /*_____FUNCIONES REDUX---------*/
  obtenerDatosEmpresa() {
    this.storeEmpresa.select('empresaInfoTotal').subscribe(
      (value: any) => {
        if (value != undefined && !value.loading && value.value != null) {
          this.companyConf = value.value;
          this.indicateLumps = this.companyConf.indicateLumps;
          this.obtenerArticulos();
        } else {
          this.defaultService.getCompanyConfig().subscribe((value: any) => {
            this.storeEmpresa.dispatch(obtenerEmpresaInfoTotal({ value }));
            this.companyConf = value;
            this.indicateLumps = this.companyConf.indicateLumps;
            this.obtenerArticulos();
          });
        }
      });
  }

  obtenerArticulos() {
    this.storeArticulos.select('articulosInfo').subscribe(
      (value: any) => {
        if (value != undefined) {
          this.obtenerInfoInicialArticulos(value);
        } else {
          this.defaultService.getNewArticles('ES', null, null, false, true).subscribe(
            (value: Array<Article>) => {
              value.forEach(el => {
                if (el.articleImgUrl == null || el.articleImgUrl == '') {
                  el.articleImgUrl = 'generica.webp'
                }
              });
              this.storeArticulos.dispatch(obtenerArticulosInfo({ value }));
            });
        }
      });
  }

  actualizarNombreNavegacion() {
    if (this.superGroups != undefined) {
      let index = this.superGroups.findIndex(el => el.groups.findIndex(gr => gr.id == this.groupId) != -1);

      if (index != -1) {
        this.superGroup = this.superGroups[index];
        this.groupName = this.superGroups[index].groups[this.superGroups[index].groups.findIndex(gr => gr.id == this.groupId)].name;
      }

    }
  }

  obtenerSuperGrupos() {
    this.storeEmpresa.select('superGruposInfo').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.superGroups = value.value;
            this.actualizarNombreNavegacion();
            this.webEstaticaService.getPosicionamiento().subscribe((posicionamiento: any) => {
              this.posicionamiento = posicionamiento;
              this.cargarPosicionarmiento();
            });
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.superGroups = value;
              this.actualizarNombreNavegacion();
              this.webEstaticaService.getPosicionamiento().subscribe((posicionamiento: any) => {
                this.posicionamiento = posicionamiento;
                this.cargarPosicionarmiento();
              });

              this.storeEmpresa.dispatch(obtenerSuperGrupo({ value }));
            });
          }
        });
  }

  obtenerFamilias() {
    this.storeEmpresa.select('familiasReducer').subscribe(
        (value: any) => {
          if (value != undefined && !value.loading && value.value != null) {
            this.families = value.value;
          } else {
            this.defaultService.getSuperGroups().subscribe((value: any) => {
              this.families = value;
              this.storeEmpresa.dispatch(obtenerFamilias({ value }));
            });
          }
        });
  }

  round(numero: number) {
    return Math.floor(numero);
  }
}
