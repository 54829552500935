<!-- ARTICLE COMPONENT-->
<div id="container mb-5" class="p-2">
  <!-- CÓDIGO PARA MOSTRAR LAS IMÁGENES DEL ARTICULO-->
  <div class="row" *ngIf="(article.articleImgUrl != '' && article.articleImgUrl != null) ||  (article.productImgUrl != '' && article.productImgUrl != null)">
    <div class="col-md-6 col-12" *ngIf="article">
      <div class="d-flex justify-content-center w-100">
        <div [id]="'carousel'" class="carousel slide" #carousel>
          <div class="carousel-indicators" *ngIf="variasFotos">
            <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="0"
                    class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselIndicators" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
          </div>


          <div class="carousel-inner" >
            <div id="carousel1" class="carousel-item active">
              <img (mouseenter)="setCarrouselImgNext()" style="border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;"
                   [src]="'assets/fotos/articulos/' + urlImg(0, article.articleImgUrl).split(' ').join('%20')"
                   [alt]="'Imágen de ' + article.name" [name]="article.name" [title]="article.name"
                   width="100%" height="auto">
            </div>
            <div id="carousel2" class="carousel-item" [hidden]="!variasFotos">
              <img (mouseleave)="setCarrouselImgPrevious()" style="border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;"
                   [src]="'assets/fotos/articulos/' + urlImg(1, article.articleImgUrl).split(' ').join('%20')"
                   [alt]="'Segunda imágen de ' + article.name" [name]="article.name" [title]="article.name"
                   width="100%" height="auto">
            </div>
          </div>

          <button class="carousel-control-prev" type="button" [hidden]="!variasFotos"
                  [id]="'buttonNext'"
                  [attr.data-bs-target]="'#carousel'" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" [hidden]="!variasFotos"
                  [id]="'buttonPrev'"
                  [attr.data-bs-target]="'#carousel'"
                  data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-12">
      <div *ngIf="article" class="p-1 w-100 text-center">
        <span class="text-center w-100 text-info" *ngIf="article.information">
          {{article.information}}
        </span>
      </div>

      <div class="article-name d-flex">
        <h1 class="text-dark name" *ngIf="article.nameClient == '' || article.nameClient == undefined;  else elseNameClient">
          {{article.name.toUpperCase()}}
          <span class="pointer" style="border: 1px solid black;" *ngIf="article.articleTag" (click)="openModalTag(tagArticle)">
            <i class=" fas fa-barcode p-1"></i>
          </span>
        </h1>

        <ng-template #elseNameClient>
          <h1 class="text-dark name">
            {{article.nameClient.toUpperCase()}}
            <span class="pointer" style="border: 1px solid black;" *ngIf="article.articleTag" (click)="openModalTag(tagArticle)">
              <i class=" fas fa-barcode p-1"></i>
            </span>
          </h1>
        </ng-template>
      </div>

      <div class="price mb-2" style="font-size: 1.5em; line-height: 120%;"
          [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
          [style]="'color: ' + companyConf.priceColor + ';'">
        {{(((article.format == 1) ? article.priceIva : (article.approxWeight > 0) ? article.priceIva * article.approxWeight : article.priceIva)
        | currency: 'EUR') +
        (article.format == 1 && companyConf.companyException != 'ENJA' ? '/Kg' :
        (article.format != 1 && companyConf.companyException != 'ENJA' ? '/Ud' : '') )}}
      </div>

      <div *ngIf="article.procedimientos" class="new-line p-1 mb-2"  >{{article.procedimientos}}</div>
      <div *ngIf="article.ingredients" class="new-line p-1  mb-2"  ><b>Ingredientes:</b> <br/>{{article.ingredients}}</div>
      <div *ngIf="article.conservantes" class="new-line p-1 mb-2"  ><b>Conservantes:</b> <br/>{{article.conservantes}}</div>
      <div *ngIf="article.metodoConservacion != ''" class="new-line p-1 mb-2"  ><b>Conservación:</b> <br/>{{article.metodoConservacion}}</div>
      <div *ngIf="article.approxWeight > 0 && article.format == '2'" class="new-line p-1 mb-2"  >
        <span *ngIf="article.approxWeight >= 1"><b>Peso aproximado:</b> <br/>{{article.approxWeight | number: '.2'}} kilo<span *ngIf="article.approxWeight > 1">s</span></span>
        <span *ngIf="article.approxWeight < 1"><b>Peso aproximado:</b> <br/>{{article.approxWeight * 1000}} gramos</span>

      </div>
      <div id="alergenos" class="mt-2 ms-2 d-flex" *ngIf="article.imagenesAlergenos != undefined">
        <div *ngFor="let imagenAlergeno of article.imagenesAlergenos" class="me-2">
          <img class="img" src="assets/fotos/alergenos/{{imagenAlergeno}}"
               alt="{{imagenAlergeno.replace('.png', '')}}"
               title="{{imagenAlergeno.replace('.png', '')}}"
               width="100px" height="auto"/>
        </div>
      </div>

      <form  [formGroup]="cutForm" novalidate>
        <div *ngIf="article.tiposCorte != undefined">
          <div class="form-group mt-2" *ngIf="article.tiposCorte.length > 0">
            <ng-select bindLabel="name"
                       formControlName="cut" (change)="cutValidFn()"

                       placeholder="Seleccione una preparación">
              <ng-option *ngFor="let cut of article.tiposCorte" [value]="cut.id">{{cut.name}}</ng-option>
            </ng-select>
          </div>
        </div>
      </form>

      <div class="select-container">
        <div class="d-flex justify-content-center w-100" *ngIf="companyConf.utilizarMultiplesFormatos"
             [ngClass]="{'oculto': article.approxWeight == undefined || article.approxWeight == 0}">
          <div class="mt-2 mb-2 p-1 pointer toggle-button-group text-decoration-none">
            <div class="w-50">
              <div class="toggle-button p-1  w-100 text-center"
                   [ngClass]="{'active': article.format != 1}"
                   id="ud" onclick="toggle('ud')" (click)="article.format = '2'; article.quantity = 0; article.lumps = 0; quantityUdForm.controls['quantity'].setValue(1); calculatePriceUd();">ud</div>
            </div>
            <div class="w-50">
              <div class="toggle-button p-1 w-100 text-center"
                   [ngClass]="{'active': article.format == 1}" (click)="article.format = '1'; quantityUdForm.controls['quantity'].setValue(0.5); calculatePriceUd();">kg</div>
            </div>
          </div>
        </div>

        <form (ngSubmit)="openModalArticle(addArticle)" [formGroup]="quantityUdForm" novalidate>
          <div class="form-group">
            <div class="select-container mt-2" >
              <div class="d-flex justify-content-center w-100 mb-2">
                <button  type="button" class="btn btn-add text-dark me-2"
                         [disabled]="this.quantityUdForm.controls['quantity'].value == undefined || this.quantityUdForm.controls['quantity'].value == 0"
                         (click)="subtractQuantity(this.article.format); calculatePriceUd();">
                  <i class="fa-solid fa-minus"></i>
                </button>

                <div class="quantity-div text-center  h-100">
                  <input type="text" class="text-center text-dark bg-light-gray font-size-title p-2 h-100" style="max-width: 100px"
                         formControlName="quantity" readonly>
                </div>

                <button  type="button" class="btn btn-add text-dark ms-2" (click)="addQuantity(article.format); calculatePriceUd();">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>

            <div *ngIf="quantityUdForm.controls.quantity.touched && quantityUdForm.controls.quantity.errors?.required"
                 class="error">Campo obligatorio.
            </div>
            <div *ngIf="quantityUdForm.controls.quantity.touched && quantityUdForm.controls.quantity.errors?.pattern"
                 class="error">Formato incorrecto. Solo se aceptan números enteros.
            </div>
            <p class="mt-1 mb-4" style="font-size: 1.15em; line-height: normal">
              Precio total: <b>{{(totalPrice| currency: 'EUR')}}</b>
            </p>
          </div>
          <div *ngIf="indicateLumps && useMinMax == true && article.format == '1'">
            <div class="d-flex mb-2">
              Número de unidades
              <div class="ms-2 help text-info" (click)="openModal(INFOUSER)">
                <i class="help fas fa-question-circle"></i>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                   (click)="addValue()"> + </div>
              <div class="form-group ms-2 me-2" style="width: 3.75em;">
                <span class="w-100 form-control text-center"> {{numberPieces}} </span>
              </div>
              <div class="btn btn-dark text-center" style="width: 2.5em; height: calc(1.5em + 0.75rem + 2px);"
                   (click)="subtractValue()"> - </div>
            </div>
          </div>

          <div *ngIf="article.tiposCorte != undefined; else elseBlock">
            <div *ngIf="article.tiposCorte.length > 0; else elseBlock">
              <button type="submit" class="btn btn-primary text-light w-100 mt-4 w-100"
                      [disabled]="!quantityUdForm.valid || !cutValid || article.price == 0" ngbAutofocus >Añadir al carrito</button>
            </div>
          </div>

          <ng-template #elseBlock>
            <button type="submit" class="btn btn-primary text-light w-100 mt-4 w-100"
                    [disabled]="!(quantityUdForm.valid || quantityUdForm.valid) || article.price == 0" ngbAutofocus >
              Añadir al carrito
            </button>
          </ng-template>
        </form>


      </div>
    </div>
  </div>
</div>

<!-- Add article POP UP -->
<ng-template #addArticle let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 d-flex justify-content-end">
      <div class="text-danger" (click)="closeModal(modal)">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>
    <div class="row" style="margin-bottom: 10px">
      <div class="col-md-6 border-right modal-info">
        <div class="w-100 text-center font-weight-bold" style="margin-bottom: 10px">
          ARTÍCULO AÑADIDO AL CARRITO
        </div>
        <div class="w-100 d-flex justify-content-center" *ngIf="article.articleImgUrl || article.productImgUrl" >
          <div id="container-img-success">
            <img *ngIf="article.articleImgUrl; else productImg"
                 width="100%" height="auto"
                 src="assets/fotos/articulos/size-s/{{article.articleImgUrl}}" alt="{{article.name}}"/>
          </div>
        </div>
        <div class="w-100 text-center" *ngIf="article.nameClient == '' ||  article.nameClient == undefined">{{article.name.toUpperCase()}}</div>
      </div>

      <div class="col-md-6 d-flex justify-content-center modal-info" *ngIf="summary">
        <div class="text-center" style="margin-bottom: 1.25em">
          <h3 class="text-dark font-weight-bold">
            <i class="fas fa-shopping-cart"></i>
            Hay <span class="text-danger">{{summary.numArticles}}</span> artículos en su carrito.<br/>
          </h3>
          <div style="margin-top:-10px"></div>
          <div style="margin-top:-10px"></div>

          <div *ngIf="differenceMinPrice <= 0 && priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0">
            <div *ngIf="homeDelivery; else pickUpStore">
              Entrega <span class="text-danger">GRATUITA</span><br/>
            </div>
            <ng-template #pickUpStore>
              RECOGIDA EN TIENDA
            </ng-template>
          </div>
          <div *ngIf="differenceMinPrice > 0 && priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0">
            <span style="font-size: 12px">Entrega no disponible:</span>
            <span class="text-danger"> Faltan {{(differenceMinPrice | currency: 'EUR')}}</span><br/>
          </div>

          <div *ngIf="!(priceRoute.minKg == 0 && priceRoute.deliveryPrice == 0 && priceRoute.deliveryKg == 0) && totalTransport > 0">
            <span style="font-size: 12px" *ngIf="priceRoute.deliveryKg != 0 && priceRoute.deliveryPrice != 0">
              Precio transporte:
              {{(priceRoute.deliveryPrice  | currency: 'EUR')}}
              cada {{priceRoute.deliveryKg}}Kg<br/><br/>
            </span>
            <span style="font-size: 12px" *ngIf="priceRoute.minKg != 0">
              Por compras inferiores a {{priceRoute.minKg}}Kg no se realizan envios.<span class=""></span>
              <br/><br/>
            </span>
            <span style="font-size: 0.875em;" class="font-weight-bold">
                TRANSPORTE:
                <span class="font-weight-light">
                  {{(totalTransport  | currency: 'EUR')}}.
                </span>
            </span>
          </div>

          <div class="item-info" *ngIf="pendientePago < 0">
            DESCUENTO acumulado
            <span class="text-danger font-weight-bold"> {{pendientePago | currency: 'EUR'}} </span>
          </div>

          <div>
            <b>TOTAL</b><span style="font-size: 12px"> (IMPUESTOS INC.) </span>
            <span [ngClass]="{'font-weight-bold': companyConf.boldPrice}"
                  [style]="'color: ' + companyConf.priceColor + ';'">{{(summary.totalPriceIva  | currency: 'EUR')}}</span><br/>
          </div>
        </div>
      </div>
    </div>

    <div class="row" id="buttons">
      <div class="col-md-6 d-flex justify-content-center">
        <a class="btn btn-dark btn-modal"
                [routerLink]="redirectToGrupo(article.groupName)"
                [queryParams]=" { superGrupo: (article.superGroup != null && article.superGroup != '') ? article.superGroup : 'none', grupo: article.group }"
                (click)="scrollTop(); modal.close();">
          CONTINUAR COMPRANDO
        </a>
      </div>
      <div class="col-md-6 d-flex justify-content-center">
        <a class="btn btn-success  btn-modal" (click)="scrollTop(); modal.close()" routerLink="/carrito">
          VER CARRITO
        </a>
      </div>
    </div>
  </div>
</ng-template>

<!-- Add article POP UP -->
<ng-template #tagArticle let-modal class="modal">
  <div class="modal-body">
    <div class="w-100 d-flex justify-content-end">
      <div class="text-danger" (click)="modal.close()">
        <i class="fas fa-times-circle fa-2x"></i>
      </div>
    </div>

    <div class="d-flex w-100 p-2" style="border: 1px solid black;">
      <div class="w-50 me-2" style="border-right: 1px solid black;" *ngIf="article.treatment">Producto: <b>{{article.treatment}}</b></div>
      <div class="w-50" *ngIf="article.obtaining">Método de producción: <b>{{article.obtaining}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="article.fao" class="w-100 d-flex justify-content-end">FAO: <b>{{article.fao}}</b></div>
      <div *ngIf="article.name">Denominación comercial: <b>{{article.name}}</b></div>
      <div *ngIf="article.scientificName">Denominación científica: <b>{{article.scientificName}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="article.capture">Zona de captura o cría: <b>{{article.capture}}</b></div>
      <div *ngIf="article.fishingGear">Arte de pesca: <b>{{article.fishingGear}}</b></div>
    </div>

    <div class="w-100 p-2" style="border-bottom: 1px solid black;border-right: 1px solid black;border-left: 1px solid black;">
      <div *ngIf="article.allergies">Alérgenos: <b>{{article.allergies}}</b></div>
    </div>
  </div>
</ng-template>


<!-- Info User -->
<ng-template #INFOUSER let-modal class="modal">
  <div class="modal-body">
    <div class="container">
      <div class="d-flex mb-2">
        <div class="font-weight-bold me-4" style="width: 90%;">
          <h1 class="text-info">Número de unidades</h1>
        </div>
        <div class="d-flex justify-content-end"
             style="width: 10%">
          <a (click)="modal.close()" class="text-danger">
            <i class="fas fa-times-circle fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="max" style="margin-bottom: 10px">
        <h4 CLASS="font-weight-bold mb-0">Atención</h4>
        <span>El número de unidades solicitadas puede ser variable dependiendo de la disponibilidad de las piezas.</span>
        <br/>
      </div>
    </div>
  </div>
</ng-template>

