import {Injectable} from '@angular/core';
import {PriceRoute} from "../../interfaces/price-route";

const userStorage = 'TOKEN';
const adminStorage = 'ADMIN';
const cp = 'CP';
const routePrice = 'ROUTE_PRICE';
const routePrice_cp = 'ROUTE_PRICE_CP';
const textosAcercaDe = 'ACERCA_DE';
@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  login(credentials) {
    localStorage.setItem( userStorage, JSON.stringify(credentials));
  }
  loginAdmin(credentials) {
    localStorage.setItem( adminStorage, JSON.stringify(credentials));
  }


  guardarTextos(textos: Array<string>) {
    localStorage.setItem( textosAcercaDe, JSON.stringify({texto: textos, servicios: []}));
  }

  setToken(token) {
    if (!(localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null)) {
      let credentials = JSON.parse(localStorage.getItem(userStorage));
      credentials.client = token;
      localStorage.setItem( userStorage, JSON.stringify(credentials));
    }
  }

  logOut() {
    localStorage.removeItem(userStorage);
    localStorage.removeItem(adminStorage);
  }

  getUser() {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).client;
    }
  }

  getTextos() {
    if (localStorage.getItem(textosAcercaDe) == '' || localStorage.getItem(textosAcercaDe) == null
      || JSON.parse(localStorage.getItem(textosAcercaDe)) == null) {
      return null;
    } else {
      console.log(JSON.parse(localStorage.getItem(textosAcercaDe)).texto)
      return  JSON.parse(localStorage.getItem(textosAcercaDe));
    }
  }

  getTokenType() {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null
      || JSON.parse(localStorage.getItem(userStorage)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).tokenType;
    }
  }

  getDiscount() {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null) {
      return 0;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).discount;
    }
  }

  setDiscount(discount: number) {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null) {
      return null;
    } else {
      let storage  = JSON.parse(localStorage.getItem(userStorage));
      storage.discount = discount;
      localStorage.setItem( userStorage, JSON.stringify(storage));
    }
  }

  getPendientePago() {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null) {
      return 0;
    } else {
      return  JSON.parse(localStorage.getItem(userStorage)).pendientePago;
    }
  }

  setPendientePago(pendientePago: number) {
    if (localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null) {
      return null;
    } else {
      let storage  = JSON.parse(localStorage.getItem(userStorage));
      storage.pendientePago = pendientePago;
      localStorage.setItem( userStorage, JSON.stringify(storage));
    }
  }

  isUserAdmin() {
    return !(localStorage.getItem(adminStorage) == '' || localStorage.getItem(adminStorage) == null);
  }


  isUser() {
    return !(localStorage.getItem(userStorage) == '' || localStorage.getItem(userStorage) == null);
  }

  setCp(value) {
    localStorage.setItem(cp, value);
  }

  setRoutePrices(value: PriceRoute) {
    localStorage.setItem(routePrice, JSON.stringify(value));
  }

  getRoutePrices(): PriceRoute {
    if (localStorage.getItem(routePrice) == '' || localStorage.getItem(routePrice) == null
      || JSON.parse(localStorage.getItem(routePrice)) == null) {
      return null;
    } else {
      return  JSON.parse(localStorage.getItem(routePrice));
    }
  }

  getCp() {
    if (localStorage.getItem(cp) == '' || localStorage.getItem(cp) == null) {
      return null;
    } else {
      return  localStorage.getItem(cp);
    }
  }

  copyInfoRoute () {
    localStorage.setItem(routePrice_cp, localStorage.getItem(routePrice));
    localStorage.setItem(routePrice, JSON.stringify(new PriceRoute(0, 0, 0, 0)));
  }

  setInfoRoute () {
    localStorage.setItem(routePrice, localStorage.getItem(routePrice_cp));
    localStorage.removeItem(routePrice_cp);
  }

  storePickup() {
    return !(localStorage.getItem(routePrice_cp) == '' || localStorage.getItem(routePrice_cp) == null);
  }
}
